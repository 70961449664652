const DisplaySkills = ({ getSkills, skillsCategory, title }) => {
  // map through object of arrays to get skills information
  const displaySkillsBasedOnCategory = getSkills
    .filter(filterSkills => filterSkills.category === skillsCategory)
    .map((skillsItem, key) => {
      const { image, title } = skillsItem;
      // icon images
      let skillsImage = <img src={image} alt={title} />;

      return (
        <>
          <li className="skill-items" key={title}>
            <span key={`${title}-${key}`} className="skills-title">
              {title}
            </span>
            <span key={title} className="skills-icon">
              {skillsImage}
            </span>
          </li>
        </>
      );
    });

  return (
    <div className="my-4 p-0 w-100">
      <h2
        className="p-3 text-white rounded"
        style={{ background: "linear-gradient(to right, #17a2b8, #fff)" }}
      >
        {title}
      </h2>
      <ul>{displaySkillsBasedOnCategory}</ul>
    </div>
  );
};

export default DisplaySkills;
