import React from "react";
import Skills from "./Skills/Skills";

const About = ({ title }) => {
  return (
    <section className="about-section d-flex flex-column" id="about">
      <h1 className="about-title">{title}</h1>
      <div className="about-columns w-100">
        <Skills />
      </div>
    </section>
  );
};

export default About;
