import DisplaySkills from "./DisplaySkills.js";
import skills from "./Skills_Array.js";

const Skills = () => {
  return (
    <section className="skills d-flex flex-column justify-content-center align-items-center">
      <DisplaySkills
        getSkills={skills}
        skillsCategory="language"
        title="Technologies"
      />

      <DisplaySkills getSkills={skills} skillsCategory="tool" title="Tools" />
    </section>
  );
};

export default Skills;
