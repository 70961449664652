import { useState } from "react";

const Navigation = ({ home, brand, skills, projects, resume, url }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark"
      id="navigation"
    >
      <div className="container-fluid d-lg-flex justify-content-between container">
        <a
          href="/"
          className="navbar-brand border border-1 border-white px-3 p-2 rounded-circle"
        >
          <span className="brand">{brand}</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isActive ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={toggleClass}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isActive ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-lg-auto">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="#"
                onClick={toggleClass}
              >
                {home}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" onClick={toggleClass}>
                {skills}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#projects" onClick={toggleClass}>
                {projects}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link resume"
                href={url}
                alt="my resume"
                target="_blank"
                rel="noopener noreferrer"
              >
                {resume}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
