const skills = [
  {
    title: "HTML5",
    image: "https://img.icons8.com/color/48/000000/html-5--v2.png",
    category: "language",
  },
  {
    title: "JavaScript",
    image: "https://img.icons8.com/color/48/000000/javascript--v1.png",
    category: "language",
  },
  {
    title: "CSS3",
    image: "https://img.icons8.com/color/48/000000/css3.png",
    category: "language",
  },
  {
    title: "NodeJs",
    image: "https://img.icons8.com/color/48/000000/nodejs.png",
    category: "language",
  },
  {
    title: "React",
    image: "https://img.icons8.com/officel/40/000000/react.png",
    category: "language",
  },
  {
    title: "MongoDB",
    image: "https://img.icons8.com/color/48/000000/mongodb.png",
    category: "language",
  },
  {
    title: "Python",
    image:
      "https://img.icons8.com/?size=50&id=l75OEUJkPAk4&format=png&color=000000",
    category: "language",
  },
  {
    title: "Postgresql",
    image: "https://img.icons8.com/?size=50&id=38561&format=png&color=000000",
    category: "language",
  },
  {
    title: "Sass",
    image: "https://img.icons8.com/color/48/000000/sass.png",
    category: "tool",
  },
  {
    title: "Bootstrap",
    image: "https://img.icons8.com/color/48/000000/bootstrap.png",
    category: "tool",
  },
  {
    title: "Express",
    image:
      "https://img.icons8.com/?size=50&id=SDVmtZ6VBGXt&format=png&color=000000",
    category: "tool",
  },
  {
    title: "EJS",
    image:
      "https://img.icons8.com/?size=50&id=Pxe6MGswB8pX&format=png&color=000000",
    category: "tool",
  },
  {
    title: "Git",
    image: "https://img.icons8.com/color/48/git.png",
    category: "tool",
  },
  {
    title: "Github",
    image: "https://img.icons8.com/material-sharp/48/github.png",
    category: "tool",
  },
];

export default skills;
