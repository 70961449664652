import React, { useState } from "react";
import Project_buttons from "./Project_buttons";
import Project_Stack from "./Project_Stack";
import Project_Description from "./Project_Description";
import Project_Image from "./Project_Image";

const Project_Items = ({ title }) => {
  return (
    <section className="project d-flex flex-column shadow-sm container">
      <div className="project-information mb-3 w-100">
        <h1 className="project-title">{title}</h1>
        <Project_Stack stack="React - MongoDB Atlas -  Express.js (JSON) - Sass - API Ninjas API - Google Maps API" />
      </div>
      <div className="project-item d-flex flex-column flex-sm-column flex-lg-row w-100">
        <div className="mb-4 me-sm-0 mb-sm-4 me-lg-4 shadow-sm">
          <Project_Image
            image="flightinrangehomepage.jpg"
            alt="Flight In Range App"
          />
        </div>
        <div className="w-100">
          <Project_Description />
          <Project_buttons
            url="https://github.com/bshaw2424/airline-frontend"
            liveUrl="https://www.Flightinrange.com"
            alt="Flight in Range Project"
            codeAlt="GitHub Code View"
            liveAlt="Live View"
          />
        </div>
      </div>
    </section>
  );
};

export default Project_Items;
