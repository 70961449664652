import "./sass/App.scss";
import Navigation from "./Navigation";
import Landing from "./Landing";
import About from "./About";
import Projects from "./Project/Projects";
import Description from "./Description";
import Footer from "./Footer";

function App() {
  return (
    <main className="App">
      <Navigation
        home="home"
        about="about"
        skills="skills"
        projects="projects"
        contact="contact"
        brand="BS"
        resume="resume"
        url="/Resume.pdf"
      />

      <Landing name="Bishop Shaw III" description={<Description />} />
      <div className="container">
        <About title="Skills" />
        <Projects title="Projects" />
      </div>
      <Footer />
    </main>
  );
}

export default App;
