import React from "react";

const Project_Stack = ({ stack }) => {
  return (
    <p className="project-stack my-3 py-3 px-5 bg-dark text-white">
      <b>{stack}</b>
    </p>
  );
};

export default Project_Stack;
