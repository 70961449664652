const Footer = () => {
  return (
    <section className="footer">
      <p className="footer-body">
        &#169; {new Date().getFullYear()} Bishop Shaw III
      </p>
    </section>
  );
};

export default Footer;
