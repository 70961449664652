import React from "react";

const Project_Image = ({ image, alt }) => {
  return (
    <>
      <img
        src={image}
        alt={alt}
        className="img-thumbnail"
        style={{ objectFit: "contain", height: "100%", width: "100%" }}
      />
    </>
  );
};

export default Project_Image;
