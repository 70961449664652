import React from "react";
import Project_Items from "./Project_Items";

const Projects = ({ title }) => {
  return (
    <section className="project-section mt-5" id="projects">
      <div>
        <h1 className="project-section_title mb-5 pb-3">{title}</h1>
        <Project_Items title="Flight in Range" />
      </div>
    </section>
  );
};

export default Projects;
