const Description = () => {
  return (
    <>
      I am a Front-end Developer who loves creating visually appealing and
      user-friendly web applications. I enjoy seeing projects evolve from idea
      to completion and am currently learning more about backend development to
      enhance my understanding of full-stack applications. Outside of coding, I
      like hiking, traveling, watching sports, and enjoying music and movies.
      Let’s connect and create something great together!
    </>
  );
};

export default Description;
