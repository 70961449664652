import React from "react";
// import Social from "./Social";
import Contact from "./Contact";

const Landing = ({ name, description }) => {
  return (
    <section className="intro-section">
      <div className="container">
        <div className="intro-info">
          <h1 className="intro">{name}</h1>
        </div>
        <div className="about-description mb-4 text-center">{description}</div>
        <Contact />
      </div>
    </section>
  );
};

export default Landing;
