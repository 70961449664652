import React from "react";

const Project_Description = () => {
  return (
    <p className="project-description">
      FlightinRange.com is an app designed to help travelers easily discover the
      destinations served by the ten largest US airlines, searchable by city
      codes (iata), state, or country. Additionally, it provides essential
      guidance on what items can be brought onboard as checked or carry-on
      luggage. Currently in development, the app will soon include more features
      to enhance the travel planning experience. Deployment: Frontend (AWS
      Amplify), Backend (Digital Ocean)."
    </p>
  );
};

export default Project_Description;
