import React from "react";

const Social = ({ linkUrl, path }) => {
  return (
    <div className="social">
      <a href={linkUrl} target="_blank">
        <span className="social-links linkedin">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-linkedin"
            viewBox="0 0 16 16"
          >
            <path d={path} />
          </svg>
        </span>
      </a>
    </div>
  );
};

export default Social;
